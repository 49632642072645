exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-mijn-khd-tsx": () => import("./../../../src/pages/mijn-khd/[...].tsx" /* webpackChunkName: "component---src-pages-mijn-khd-tsx" */),
  "component---src-pages-nieuws-en-media-agenda-2023-05-05-concertreis-italie-tsx": () => import("./../../../src/pages/nieuws-en-media/agenda/2023-05-05-concertreis-italie.tsx" /* webpackChunkName: "component---src-pages-nieuws-en-media-agenda-2023-05-05-concertreis-italie-tsx" */),
  "component---src-pages-programma-reis-om-de-wereld-in-90-minuten-tsx": () => import("./../../../src/pages/programma/reis-om-de-wereld-in-90-minuten.tsx" /* webpackChunkName: "component---src-pages-programma-reis-om-de-wereld-in-90-minuten-tsx" */),
  "component---src-templates-aanmelden-tsx": () => import("./../../../src/templates/aanmelden.tsx" /* webpackChunkName: "component---src-templates-aanmelden-tsx" */),
  "component---src-templates-agenda-item-tsx": () => import("./../../../src/templates/AgendaItem.tsx" /* webpackChunkName: "component---src-templates-agenda-item-tsx" */),
  "component---src-templates-agenda-tsx": () => import("./../../../src/templates/agenda.tsx" /* webpackChunkName: "component---src-templates-agenda-tsx" */),
  "component---src-templates-album-page-tsx": () => import("./../../../src/templates/AlbumPage.tsx" /* webpackChunkName: "component---src-templates-album-page-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/CmsPage.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-link-in-bio-tsx": () => import("./../../../src/templates/linkInBio.tsx" /* webpackChunkName: "component---src-templates-link-in-bio-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/NewsItem.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-nieuws-tsx": () => import("./../../../src/templates/nieuws.tsx" /* webpackChunkName: "component---src-templates-nieuws-tsx" */),
  "component---src-templates-photos-page-tsx": () => import("./../../../src/templates/PhotosPage.tsx" /* webpackChunkName: "component---src-templates-photos-page-tsx" */),
  "component---src-templates-social-media-tsx": () => import("./../../../src/templates/social-media.tsx" /* webpackChunkName: "component---src-templates-social-media-tsx" */)
}

